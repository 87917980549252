import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/product-1/',
            name: 'Product1',
            component: () => import(/* webpackChunkName: "home" */'../views/ProductPage')
        },
        {
            path: '/product-2/',
            name: 'Product2',
            component: () => import(/* webpackChunkName: "home" */'../views/ProductPage')
        },
        {
            path: '/manufacturers/',
            name: 'Manufacturers',
            component: () => import(/* webpackChunkName: "home" */'../views/ManufacturersPage')
        },
        {
            path: '/research/',
            name: 'Research',
            component: () => import(/* webpackChunkName: "home" */'../views/ResearchAndDevelopmentPage')
        },
        {
            path: '/supply-chain/',
            name: 'SupplyChain',
            component: () => import(/* webpackChunkName: "home" */'../views/SupplyChainPage')
        },
        {
            path: '/sustainability/',
            name: 'Sustainability',
            component: () => import(/* webpackChunkName: "home" */'../views/SustainabilityPage')
        },
        // Applications
        {
            path: '/sports/',
            name: 'Sports',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/SportsPage')
        },
        {
            path: '/ageing/',
            name: 'Ageing',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/AgeingPage')
        },
        {
            path: '/inflammation/',
            name: 'Inflammation',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/InflammationPage')
        },
        {
            path: '/lipids/',
            name: 'Lipids',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/LipidsPage')
        },
        {
            path: '/eye-health/',
            name: 'EyeHealth',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/EyeHealthPage')
        },
        {
            path: '/blood-sugar/',
            name: 'BloodSugar',
            component: () => import(/* webpackChunkName: "home" */'../views/applications/BloodSugarPage')
        },
        // Product Ranges
        // Nutraceutical
        {
            path: '/Curcumin-Extract/',
            name: 'CurcuminExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/CurcuminExtract ')
        },
        {
            path: '/Cur-o-stain/',
            name: 'Cur-o-stain',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/Cur-o-stain')
        },
        {
            path: '/f-biotic/',
            name: 'F-biotic',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/human-nutrition/f-biotic/F-biotic')
        },

        {
            path: '/CURO-DIS-95/',
            name: 'CURO-DIS-95',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/CUR-DIS-95')
        },

        {
            path: '/Lutein-Extract/',
            name: 'LuteinExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/LuteinExtract')
        },
        {
            path: '/Naturalessentialoil/',
            name: 'Naturalessentialoil',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/essentialoil/Naturalessentialoil')
        },
        {
            path: '/Naturalcolors/',
            name: 'Naturalcolors',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/natural-color/Naturalcolors')
        },
        {
            path: '/NaturalAnti-oxidant/',
            name: 'Naturalanti-oxidant',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/natural-anti-oxidants/Naturalanti-oxidant')
        },
        {
            path: '/other-botanical-extract/',
            name: 'Standard-nutra-ingredient',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/standard-nutra-ingredient/Standardnutra-ingredient')
        },
        {
            path: '/Spiceandherbextract/',
            name: 'Spiceandherbextract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/spice-and-herb-extract/Spiceandherbextract')
        },
        {
            path: '/Resistant-Starch/',
            name: 'ResistantStarch',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/ResistantStarch')
        },
        {
            path: '/Amla-Extract/',
            name: 'AmlaExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/AmlaExtract')
        },
        {
            path: '/Green-Coffee-Bean-Extract/',
            name: 'GreenCoffeeBeanExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/GreenCoffeeBeanExtract')
        },
        {
            path: '/Green-Tea-Extract/',
            name: 'GreenTeaExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/GreenTeaExtract')
        },
        {
            path: '/Piperine-Extract/',
            name: 'PiperineExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/nutraceutical/PiperineExtract')
        },
        // Spice Extract
        {
            path: '/Green-Chilly-Extract/',
            name: 'GreenChillyExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/spice-extract/GreenChillyExtract')
        },
        {
            path: '/Ginger-Green-Extract/',
            name: 'GingerGreenExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/spice-extract/GingerGreenExtract')
        },
        {
            path: '/Pepper-Green-Extract/',
            name: 'PepperGreenExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/spice-extract/PepperGreenExtract')
        },
        {
            path: '/Cardamom-Green-Extract/',
            name: 'CardamomGreenExtract',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/spice-extract/CardamomGreenExtract')
        },
        // Media
        {
            path: '/media/',
            name: 'MediaPage',
            component: () => import(/* webpackChunkName: "home" */'../views/media/MediaMainPage')
        },
        {
            path: '/news-article/',
            name: 'NewsArticle',
            component: () => import(/* webpackChunkName: "home" */'../views/media/NewsArticlePage')
        },
        {
            path: '/contact/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/ContactUs')
        },
        {
            path: '/news-businessline/',
            name: 'NewsBusinessline',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsPageBusinessline')
        },
        {
            path: '/news-page-nutragradient/',
            name: 'NewsPageNutragradient',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsPageNutraingredients')
        },
        {
            path: '/news-page-nutraceutical-business/',
            name: 'NewsNutraceuticalBusiness',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsNutraceuticalBusiness')
        },
        {
            path: '/news-page-deccan-cronicle/',
            name: 'NewsDeccanCronicle',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsDeccanCronicle')
        },
        {
            path: '/news-page-nufffoods-spectrum/',
            name: 'NewsNufffoodsSpectrum',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsNufffoodsSpectrum')
        },
        {
            path: '/news-page-agrospectrum/',
            name: 'NewsPageAgroSpectrum',
            component: () => import(/* webpackChunkName: "home" */'../views/media/news-pages/NewsPageAgroSpectrum')
        },
        {
            path: '/phyto-res-technology/',
            name: 'PhytoResTechnologyPage',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/human-nutrition/PhytoResTechnologyPage')
        },
        {
            path: '/d-sperse-technology/',
            name: 'DSperseTechnology',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/human-nutrition/DSperseTechnologyPage')
        },
        // human nutrition - > standard extracts
        // {
        //     path: '/other-botanical-extracts/',
        //     name: 'OtherBotanicalExtracts',
        //     component: () => import(/* webpackChunkName: "home" */'../views/product-range/human-nutrition/standard-extracts/OtherBotanicalExtractsPage')
        // },
        // animal nutrition
        {
            path: '/animal-nutrition-for-ruminants/',
            name: 'AnimalNutritionForRuminants',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/animal-nutrition/cur-liv/AnimalNutritionRuminantsPage')
        },
        {
            path: '/animal-nutrition-for-poultry/',
            name: 'AnimalNutritionForPoultry',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/animal-nutrition/cur-liv/AnimalNutritionPoultryPage')
        }, {
            path: '/cur-liv-technology-page/',
            name: 'CurLivTechnologyPage',
            component: () => import(/* webpackChunkName: "home" */'../views/product-range/animal-nutrition/cur-liv/CurLivTechnologyPage')
        },
        {
            path: '/downloads/',
            name: 'BrochureDownloadsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/media/downloads/OurProductsPage')
        }

    ]
};
