import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueScrollTo from 'vue-scrollto';

import lego from './plugins/lego';

import 'lego-framework/src/scss/variables/lego-variables.scss';
import './assets/style.scss';

import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';
import VueNumber from 'vue-number-animation';
import Box from 'lego-framework/src/containers/Box';
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;

Vue.use(lego);
Vue.use(VueNumber);
Vue.use(VueScrollTo);
Vue.component('Box', Box);
// AOS.init({ delay: 2500 });
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
        AOS.init();
    }
};
