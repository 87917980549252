import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';

Vue.use(VueRouter);

const routes = [
    appRoutes
];

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}
const scrollBehavior = async function (to, from, savedPosition) {
    if (to.hash) {
        console.log('to.hash', to.path, to.hash);
        // this.$router.push(to);
        return window.scrollTo({
            top: document.getElementById(to.hash).offsetTop,
            behavior: 'smooth'
        });
        // setTimeout(function () {
        //     console.log('to.hash', to.path, to.hash);
        //     return window.scrollTo({
        //         top: document.getElementById(to.hash).offsetTop,
        //         behavior: 'smooth'
        //     });
        // }, 1000);
    } else {
        return { x: 0, y: 0 };
    }
};
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return {
    //             x: 0,
    //             y: 0
    //         };
    //     }
    // }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
